var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.assignation'),
            placement: 'top-end'
          }),expression:"{\n            content: $t('label.assignation'),\n            placement: 'top-end'\n          }"}],staticClass:"d-flex align-items-center",attrs:{"shape":"square","color":"add"},on:{"click":function($event){(_vm.isEdit =false, _vm.DeckModal = true)}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("label.nueva")))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"sorter":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"active-page":_vm.ActivePage,"pagination":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('div',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t("label." + item.Status))+" ")])],1)])]}},{key:"Details",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: `${_vm.$t('label.edit')} ${_vm.$t('label.assignation')}`,
                  placement: 'top-start',
                }),expression:"{\n                  content: `${$t('label.edit')} ${$t('label.assignation')}`,\n                  placement: 'top-start',\n                }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.EditDeck(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1),_c('DeckModal',{attrs:{"modal":_vm.DeckModal,"isEdit":_vm.isEdit,"DeckItem":_vm.DeckItem},on:{"Loading":function($event){_vm.Loading=$event},"Update":_vm.getStowagePlanningBlDeck,"Close":function($event){_vm.DeckModal=false, _vm.DeckItem = {}}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }