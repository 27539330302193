<template>
  <div class="mt-3">
		<loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end">
        <CButton
          shape="square"
          color="add"
          v-c-tooltip="{
            content: $t('label.assignation'),
            placement: 'top-end'
          }" 
          class="d-flex align-items-center"
          @click="(isEdit =false, DeckModal = true)"
        >
          <CIcon name="cil-playlist-add" /><span class="ml-1">{{$t("label.nueva")}}</span>
        </CButton>
      </CCol>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          sorter
          column-filter
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items="formatedItems"
          :fields="fields"
          :noItemsView="tableText.noItemsViewText"
          :items-per-page="5"
          :active-page="ActivePage"
          pagination
        >
          <template #Status="{ item }">
            <td class="text-center">
              <div>
                <CBadge :color="getBadge(item.Status)">
                  {{ $t("label." + item.Status) }}
                </CBadge>
              </div>
            </td>
          </template>
          <template #Details="{ item }">
            <td class="text-center">
              <CButton
                color="edit"
                size="sm"
                class="mr-1"
                v-c-tooltip="{
                  content: `${$t('label.edit')} ${$t('label.assignation')}`,
                  placement: 'top-start',
                }"
                @click="EditDeck(item)"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <DeckModal
      :modal="DeckModal"
      :isEdit="isEdit"
      :DeckItem="DeckItem"
      @Loading="Loading=$event"
      @Update="getStowagePlanningBlDeck"
      @Close="DeckModal=false, DeckItem = {}"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateFormater, NumberFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import DeckModal from "./deck-modal";

//data
function data() {
  return {
    Loading: false,
    isEdit: false,
    DeckModal: false,
    DeckItem: {},
    DeckList: [],
    ActivePage: 1,
  };
}

//methods
function getStowagePlanningBlDeck() {
  this.Loading = true;
  this.$http
    .get("StowagePlanningBlDeck-list", { StowagePlanningId: this.planificacionId, VesselHoldHatchCId: ''})
    .then((response) => {
      this.DeckList = response?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}

function EditDeck(item) {
  this.isEdit = true;
  this.DeckItem = item;
  this.DeckModal = true;
}

//computed
function formatedItems() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + this.$i18n.locale.slice(1)
  return this.DeckList.map((item) =>{
    return {
      ...item,
      Nro: item.Nro,
      NroBl: item.NroBl ?? '',
      PackagingName: item['PackagingName'+_lang] ?? '',
      Weight: NumberFormater.formatNumber(item.WeightAsigned, 2),
      QuantityAsigned: item.QuantityAsigned ?? 0,
      VesselUbicationName: item['VesselUbicationName'+_lang] ?? '',
      VesselHoldName: item.VesselHoldName ?? 'N/A',
      HatchCoverName: item.HatchCoverName ?? 'N/A',
      Usuario: item.TransaLogin ? item.TransaLogin : "N/A",
      Fecha: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : "N/A",
      Status: item.Status,
      _classes: item.Status === "INACTIVO" ? "table-danger" : "",
    }
  });
}

function fields() {
  return [
    {
      key: "Details",
      label: "",
      sorter: false,
      filter: false,
      _style: "min-width:45px; width:1%;",
    },
    { key: "Nro", label: "#", _style: "width: 1%; min-width: 45px;", _classes: "text-center", filter: false,},
    { key: "NroBl", label: 'BL',  _classes: 'text-center', _style: "min-width: 130px;"},
    { key: "PackagingName", label: this.$t("label.packaging"), _classes: 'text-center', _style: "min-width: 130px;"},
    { key: "Weight", label: `${this.$t("label.weight")} (TON)`, _classes: 'text-center', _style: "min-width: 130px;"},
    { key: "QuantityAsigned", label: this.$t("label.quantity"), _classes: 'text-center', _style: "min-width: 130px;"},
    { key: "VesselUbicationName", label: this.$t("label.location"),  _classes: 'text-center', _style: "min-width: 130px;"},
    { key: "VesselHoldName", label: this.$t("label.hold"),  _classes: 'text-center', _style: "min-width: 130px;"},
    { key: "HatchCoverName", label: this.$t("label.hatch"),  _classes: 'text-center', _style: "min-width: 130px;"},
    { key: "Usuario", label: this.$t("label.user"), _classes: 'text-center', _style: "min-width: 130px;"},
    { key: "Fecha", label: this.$t("label.date"), _classes: "text-center", _style: "min-width: 130px;"},
    { key: "Status", label: this.$t("label.status"), _classes: "text-center", _style: "min-width: 130px;"},
  ];
}


export default {
  name: "deck",
  mixins: [General],
  data,
	props: {
		active: {
			type: Boolean,
			default: false,
		}
	},
  components: {
    DeckModal,
  },
  methods: {
    getStowagePlanningBlDeck,
    EditDeck,
  },
  computed: {
    fields,
    formatedItems,
    ...mapState({
      planificacionId: state => state.planificacionestiba.planificacionId,
      bulkCarrier: state => state.planificacionestiba.bulkCarrier,
      FgGeneralCargo: state => state.planificacionestiba.FgGeneralCargo,
    }),
  },
  watch: {
    active: function (Newval) {
      if (Newval) {
        this.getStowagePlanningBlDeck();
      }else{
        this.ActivePage = 1;
        this.DeckList = [];
        this.DeckItem = {};
      }
    },
  },
};
</script>